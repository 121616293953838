<template>

<app-content-box class="details" title="Details" icon="public" :not-padded="true" :freeze="status === $constants.status.loading">

	<div class="details-wrapper">

		<div class="details-preview" :class="{'is-loading': is.generating}">

			<app-icon class="details-preview-loading" icon="loading" v-if="is.generating" />
			
			<img :src="preview" class="details-preview-image" v-on:load="is.generating = false" />
			
		</div>

		<div class="details-form">

			<p v-if="badge.can_rename">Please use appropriate names on your badge. {{ $organisation.name }} reserve the right to change any names it deems inappropriate at its sole discretion.</p>

			<p v-if="!badge.can_rename">Badges can no longer be renamed.</p>

			<app-input-text :disabled="!badge.can_rename" label="First name" v-model="model.firstname" :validation="$v.model.firstname" placeholder="Enter your first name" maxlength="32" />
			<app-input-text :disabled="!badge.can_rename" label="Last name" v-model="model.lastname" :validation="$v.model.lastname" placeholder="Enter your last name" maxlength="32" />

			<app-button text="Save" v-if="badge.can_rename" :disabled="!isValid" :loading="status === $constants.status.loading" v-on:click="onSubmitClick" :status="status" />

		</div>

	</div>

</app-content-box>

</template>

<script>

import mixForm from '@/mixin/form'
import { required } from 'vuelidate/lib/validators'

export default {

	mixins: [mixForm],

	props: ['badge', 'preview'],

	data: function() {

		return {
			is: {
				generating: true
			},
			standalone: true,
			status: false,
			model: {
				firstname: '',
				lastname: ''
			}
		}

	},
	
	validations: {
		model: {
			firstname: {
				required
			},
			lastname: {
				required
			}
		}
	},

	created: function() {

		this.model.firstname = this.badge.firstname
		this.model.lastname = this.badge.lastname

	},

	methods: {

		onSubmitClick: function() {

			this.status = this.$constants.status.loading

			this.$api.put('convention/registration/badge', this.model).then(function() {

				this.status = this.$constants.status.success

				this.is.generating = true

				this.$emit('changed')
				
			}.bind(this), function() {

				this.status = this.$constants.status.failed

			}.bind(this))

		}

	}

}

</script>

<style scoped>

.details-wrapper {
	display: flex;
}

.is-mobile .details-wrapper {
	flex-direction: column;
}

.details-preview {
	border-right: 1px solid #eee;
	flex-shrink: 0;
	width: 385px;
	height: 288px;
}

.is-mobile .details-preview {
	width: 100%;
	height: auto;
	border-right: 0px;
	border-bottom: 1px solid #eee;
}

.is-mobile .details-preview img {
	max-width: 100%;
}

.details-preview.is-loading .details-preview-image {
	opacity: 0;
}

.details-preview-loading {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	font-size: 32px;
	color: #ffbe7a;
}

.details-form {
	padding: 20px;
	flex-grow: 1;
}

.details-form p {
	font-size: 14px;
	line-height: 18px;
	margin-bottom: 20px;
}

</style>
